import { Link } from "gatsby"
import React from "react"
import HubspotForm from "react-hubspot-form"
import "./header.scss"
import { CSSTransition } from "react-transition-group"

const Header = ({ triggerForm, cb, formOptions }) => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <header>
      <nav className="container">
        <Link to="/">
          <span className="brandLogo bgImg"></span>
        </Link>
        <button onClick={() => setMenuOpen(true)}>
          <span className="hamburger bgImg"></span>
        </button>
      </nav>
      {menuOpen && (
        <>
          <ul className="navLinks">
            <li className="navLink">
              <a href="/experts">Experts</a>
            </li>
            <li className="navLink">
              <a href="/platform">Platform</a>
            </li>
            <li className="navLink">
              <a href="/workshops">Programs</a>
            </li>
            <li className="navLink">
              <a
                onClick={() => {
                  cb(true)
                  setMenuOpen(false)
                }}
                href="#"
              >
                Contact us
              </a>
            </li>
          </ul>
          <div
            onClick={() => setMenuOpen(false)}
            className="blueBackdrop"
          ></div>
        </>
      )}
      {triggerForm && (
        <CSSTransition in={triggerForm} timeout={300} unmountOnExit>
          <div>
            <div className="rightDrawer">
              <div className="drawerContent">
                <button className="closeIcon" onClick={() => cb(false)}>
                  X
                </button>
                <h1>{formOptions ? formOptions.heading : "Contact Us"}</h1>
                <HubspotForm
                  portalId="4273486"
                  formId={
                    formOptions
                      ? formOptions.id
                      : "8b55c90a-66fe-4e0e-bc93-0e6e2ef19015"
                  }
                  onSubmit={() => cb(!triggerForm)}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
            <div onClick={() => cb(false)} className="blueBackdrop"></div>
          </div>
        </CSSTransition>
      )}
    </header>
  )
}

export default Header
