import { Link } from "gatsby"
import React from "react"
import "./footer.scss"
import { Helmet } from "react-helmet"

const Footer = ({ cb }) => (
  <footer>
    <div className="footLinks container">
      <div className="footNavLinks">
        <span className="brandLogo bgImg"></span>
        <ul className="footLinkList">
          <li className="footLinkItem">
            <Link to="/experts">Experts</Link>
          </li>
          <li className="footLinkItem">
            <Link to="/platform">Platform</Link>
          </li>
          <li className="footLinkItem">
            <Link to="/workshops">Programs</Link>
          </li>
          <li className="footLinkItem">
            <Link to="#" onClick={() => cb(true)}>
              Contact us
            </Link>
          </li>
        </ul>
        {/* <ul className="footLinkList">
          <li className="footLinkItem">
            <a href="/experts">About Us</a>
          </li>
          <li className="footLinkItem">
            <a href="/platform">Careers</a>
          </li>
          <li className="footLinkItem">
            <Link to="/workshops">Contact us</Link>
          </li>
        </ul> */}
      </div>
      <div className="footContact">
        <div>
          <span>
            <strong>HYDERABAD</strong>
          </span>
          <span className="address">
            Knowledge Capital Services Pvt Ltd. Suite #304, Delta Seacon, Road
            No. 11, Banjara Hills, Hyderabad – 500034, INDIA.
          </span>
          <span className="phoneNum">
            Phone: +91 40 40055307
            {/* <a href="tel:+914040055307">Phone: +91 40 40055307</a> */}
          </span>
          <span className="emailTxt">
            <strong>Anil Kumar</strong> <br />
            <a href="mailto:anil@kcapital-us.com">anil@kcapital-us.com</a>
          </span>
        </div>
        <div>
          <span>
            <strong>MUMBAI</strong>
          </span>
          <span className="address">
            Knowledge Capital Services Pvt Ltd. A - 24, Kalpataru Towers,
            Station Road, Off Akurli Road, Kandivali East, Mumbai - 400101,
            INDIA.
          </span>
          <span className="phoneNum">
            Phone: +91 22 28845186
            {/* <a href="tel:+9102228845186">Phone: +91 022 28845186</a> */}
          </span>
          <span className="phoneNum">
            Mobile: +91 9320048060
          </span>
          {/* <span className="phoneNum">
            Fax: +91 22 2884-5612
            <a href="tel:+912228845612">Fax: +91 22 2884-5612</a>
          </span> */}
          <span className="emailTxt">
            <strong>Kavita Mistry</strong> <br />
            <a href="mailto:kavitam@kcapital-us.com">kavitam@kcapital-us.com</a>
          </span>
        </div>
      </div>
    </div>
    <div className="tail container">
      <span className="copyRight">
        Copyright © 2006-2020, Knowledge Capital Serivces Pvt. Ltd. <br />
        “Kcapital” and logo are proprietary trademarks of Knowledge Capital
        Serivces Pvt. Ltd
      </span>
      <div className="terms">
        <a href="/terms">Terms & Conditions</a>
        <a href="/privacy">Privacy Policy</a>
        <a href="/disclaimer">Disclaimer</a>
        <a href="/cancellation">Cancellation Return & Refund Policy</a>
      </div>
    </div>
    <Helmet>
      <script type="text/javascript">{`_linkedin_partner_id = "2728084"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}</script>
      <script type="text/javascript">{`(function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})();`}</script>
      <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2728084&fmt=gif" />
    </Helmet>
  </footer>
)

export default Footer
